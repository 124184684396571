import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { useModal } from "../../hooks/useModal";
import { ILanguageValueEnum, languagesList, languageValueMapToIconName } from "../../types/localization";
import { CustomButton, IButtonVariant } from "../CustomButton";
import { CustomModal } from "../CustomModal";
import { Icon } from "../Icon";
import { LanguageIndicator } from "../LanguageIndicator";
import { RoundedCheckbox } from "../RoundedCheckbox";

import styles from "./ModalLanguageSwitcher.module.scss";

export const ModalLanguageSwitcher: React.FC = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const language = i18next.language as ILanguageValueEnum;

  const [currentLanguage, setCurrentLanguage] = useState<ILanguageValueEnum>(language);

  const handleSave = () => {
    i18next.changeLanguage(currentLanguage);
    modal.toggleModalVisibility();
  };

  const handleLanguageChange = (newLanguage: ILanguageValueEnum) => {
    setCurrentLanguage(newLanguage);
  };

  return (
    <>
      <LanguageIndicator onClick={modal.toggleModalVisibility} />
      <CustomModal
        title="languageSwitcher.title"
        isModalVisible={modal.isModalVisible}
        toggleModalVisibility={modal.toggleModalVisibility}
      >
        <div className={styles.container}>
          <div className={styles.languagesListContainer}>
            {languagesList.map((lang) => (
              <div key={lang} className={styles.languageContainer} onClick={() => handleLanguageChange(lang)}>
                <div className={styles.language}>
                  <Icon name={languageValueMapToIconName[lang]} />
                  <span>{t(`language.${lang}`)}</span>
                </div>
                <RoundedCheckbox checked={currentLanguage === lang} />
              </div>
            ))}
          </div>
          <div className={styles.buttonsContainer}>
            <CustomButton onClick={handleSave} text={t("languageSwitcher.saveLanguage")} />
            <CustomButton
              onClick={modal.toggleModalVisibility}
              variant={IButtonVariant.neutral}
              text={t("languageSwitcher.cancel")}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};
