import { useSelector } from "react-redux";

import { DataTable } from "../../../components/DataTable";
import {
  setLocationDetailVisitsPagination,
  setLocationDetailVisitsScrollTop,
} from "../../../reducers/locationDetailReducer";
import {
  getIsLocationDetailVisitStatusLoading,
  getLocationDetail,
  getLocationDetailScrollTop,
  getLocationDetailVisits,
} from "../../../selectors/locationDetail";
import { useGetLocationDetailTableColumns } from "./useGetLocationDetailTableColumns";
import { useGetLocationDetailTableHandlers } from "./useGetLocationDetailTableHandlers";

export const LocationDetailTable: React.FC = () => {
  const data = useSelector(getLocationDetailVisits);
  const columns = useGetLocationDetailTableColumns();
  const isLoading = useSelector(getIsLocationDetailVisitStatusLoading);
  const { handleAdditionalRowClick } = useGetLocationDetailTableHandlers();
  const scrollTop = useSelector(getLocationDetailScrollTop);
  const locationDetailState = useSelector(getLocationDetail);

  return (
    <DataTable
      dataRows={data}
      columns={columns}
      scrollTop={scrollTop}
      setScrollTop={setLocationDetailVisitsScrollTop}
      setPagination={setLocationDetailVisitsPagination}
      isLoading={isLoading}
      sliceState={locationDetailState}
      handleAdditionalRowClick={handleAdditionalRowClick}
      hasDividerBetweenRows
    />
  );
};
