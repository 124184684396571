import { isArray } from "lodash";

import { IFilterItem } from "../../types/filters";

export const shouldRemoveLocalFilter = (filter: IFilterItem): boolean => {
  const isEmptyArray = isArray(filter.value) && filter.value.length === 0;
  const isEmptyString = filter.value === "";

  return isEmptyString || isEmptyArray;
};
