import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useQrScanner } from "../../hooks/useQRScanner";
import { LOCATION_DETAIL_PAGE_PATH } from "../../middleware/routes";
import {
  getLocationDetailVisitsThunk,
  postQRCodeVerify,
  resetLocationDetailState,
  setLocationDetailLocationId,
} from "../../reducers/locationDetailReducer";
import { SearchParams } from "../../types/searchParams";
import { getRouteUrlWithParams } from "../../utils/route";
import { ButtonWithoutDefaultStyling } from "../ButtonWithoutDefaultStyling/ButtonWithoutDefaultStyling";
import { showCustomToast } from "../CustomToast";
import { Icon, IconNameEnum } from "../Icon";

import styles from "./StartReceivingWasteBanner.module.scss";

export const StartReceivingWasteBanner: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { qrResult, setQrResult, startQrScanner } = useQrScanner();

  useEffect(() => {
    if (qrResult) verify(qrResult);
  }, [qrResult]);

  const verify = async (qr: string) => {
    const locationId = await postQRCodeVerify(qr);

    if (!locationId) {
      showCustomToast(t("startReceivingWasteBanner.qrCodeVerifyError"), { type: "error" });
      setQrResult(null);
      return;
    }

    dispatch(resetLocationDetailState());
    dispatch(setLocationDetailLocationId(Number(locationId)));
    dispatch(getLocationDetailVisitsThunk());
    history.push(getRouteUrlWithParams(LOCATION_DETAIL_PAGE_PATH, { [SearchParams.LocationId]: locationId }));
  };

  return (
    <ButtonWithoutDefaultStyling className={styles.container} onClick={startQrScanner}>
      <div className={styles.containerTitle}>
        <h6 className={styles.title}>{t("startReceivingWasteBanner.title")}</h6>
        <p className={styles.text}>{t("startReceivingWasteBanner.text")}</p>
      </div>
      <div className={styles.containerIcon}>
        <Icon name={IconNameEnum.scanQR} />
      </div>
    </ButtonWithoutDefaultStyling>
  );
};
