import { Dayjs } from "dayjs";
import i18next from "i18next";

/**
 * Parses string date in format 'DD. MM. YYYY' into Date object
 *
 * @param dateString string in format 'DD. MM. YYYY'
 * @returns null when format doesn't match, Date object otherwise
 */
function getDateFromString(dateString: string): Date | null {
  const [day, month, year] = dateString.split(". ");
  const parsedDate = new Date(`${year}-${month}-${day}`);

  return isNaN(parsedDate.getTime()) ? null : parsedDate;
}

export function getParsedDateFromStringOrCurrentDate(date: string): Date {
  const parsedDate = getDateFromString(date);
  return parsedDate ? parsedDate : new Date();
}

/**
 * Converts date string in ISO format to ascending date format
 *
 * @param dateString Date string in ISO format
 * @returns string in format 'DD. MM. YYYY'
 */
export function getStringFromISOString(dateString: string): string {
  const date = new Date(dateString);
  return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`;
}

export const getEndOfYearDate = (year: number) => {
  return new Date(year, 11, 31, 23, 59, 59);
};

export const getStartOfYearDate = (year: number) => {
  return new Date(year, 0, 1, 0, 0, 0);
};

/**
 * Get localized weekday from date string
 *
 * @param dateString Date string in ISO format
 * @returns localized weekday string
 */
export const getLocalizedWeekday = (dateString: string) => {
  return new Date(dateString).toLocaleDateString(i18next.language, { weekday: "long" });
};

/**
 * Transforms an ISO date string to have milliseconds set to 59.999.
 * @param {string} isoString - The ISO date string to transform.
 * @returns {string} - The transformed ISO date string.
 */
export const transformTimeToEndOfMinute = (isoString: string): string => {
  const date = new Date(isoString);

  date.setSeconds(59);
  date.setMilliseconds(999);

  return date.toISOString();
};

export const getDateStringFromDayjs = (date: Dayjs | null): string | null => {
  if (!date) return null;
  return new Date(Date.UTC(date.year(), date.month(), date.date(), 0, 0, 0)).toISOString();
};

export const getDateTimeStrings = (date: Date) => {
  return {
    date: `${padDate(date.getDate())}. ${padDate(date.getMonth() + 1)}. ${date.getFullYear()}`,
    time: `${padDate(date.getHours())}:${padDate(date.getMinutes())}`,
  };
};

const padDate = (n: number) => {
  return String(n).padStart(2, "0");
};

export function getShortDateFromISOString(dateString: string): string {
  const date = new Date(dateString);
  return `${padDate(date.getDate())}.${padDate(date.getMonth() + 1)}.${date.getFullYear().toString().slice(2)}`;
}

export const isDateToday = (dateString: string) => {
  const date = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  date.setHours(0, 0, 0, 0);

  return date.getTime() === today.getTime();
};

export const getCurrentDate = () => {
  return new Date().getDate();
};
