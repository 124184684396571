import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TextInput } from "../../components/TextInput";
import { RootState } from "../../store";
import { FilterOperator, IFilterItem } from "../../types/filters";

interface Props {
  fieldName: string;
  filterSelectorValue: (state: RootState, fieldName: string) => IFilterItem | undefined;
  filterUpdateAction: (item: IFilterItem) => void;
}

export const TextFilter: React.FC<Props> = ({ fieldName, filterSelectorValue, filterUpdateAction }) => {
  const value = useSelector((state: RootState) => filterSelectorValue(state, fieldName));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <TextInput
      placeHolder={t(`filters.${fieldName}`)}
      value={String(value?.value ?? "")}
      onChange={(e) => {
        dispatch(
          filterUpdateAction({
            id: fieldName,
            name: fieldName,
            operator: FilterOperator.Contains,
            value: e.target.value,
          })
        );
      }}
    />
  );
};
