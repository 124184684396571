import { createSelector } from "@reduxjs/toolkit";

import { shouldRemoveLocalFilter } from "../reducers/utils/localFilters";
import { RootState } from "../store";
import { Status } from "../types/state";

export const getLocations = (state: RootState) => state.locations;
export const getLocationsListStatus = (state: RootState) => state.locations.status;
export const getIsLocationsListStatusLoading = (state: RootState): boolean => state.locations.status !== Status.success;

export const getLocationsListErrorMsg = (state: RootState) => state.locations.errorMsg;
export const getLocationsList = (state: RootState) => state.locations.locations;
export const getLocationsNextPageState = (state: RootState) => state.locations.isNextPageEnable;

export const getLocationsPagination = (state: RootState) => state.locations.pagination;
export const getLocationsOrdering = (state: RootState) => state.locations.ordering;
export const getLocationsFilters = (state: RootState) => state.locations.filters;
export const getLocationTabFilter = (state: RootState) => state.locations.tabFilter;
export const getLocationsFiltersItems = (state: RootState) => state.locations.filters.items;

const getterLocationsFilterValue = (state: RootState, filterId: string) =>
  state.locations.filters.items.find((item) => item.id === filterId);

export const getLocationsFilterValue = createSelector([getterLocationsFilterValue], (items) => items);

export const getLocationsSearch = (state: RootState) => state.locations.search;
export const getLocationsScrollTop = (state: RootState) => state.locations.scrollTop;

export const getLocationsOrderingValue = (state: RootState, fieldName: string) =>
  fieldName.toLowerCase() === state.locations.ordering.orderBy.toLowerCase()
    ? state.locations.ordering.descending
      ? "desc"
      : "asc"
    : null;

const getterLocationsFilterIsActive = (state: RootState, fieldName: string) =>
  !!state.locations.filters.items.find(
    (item) => String(item.id).startsWith(fieldName) && !shouldRemoveLocalFilter(item)
  );
export const getLocationsFilterIsActive = createSelector([getterLocationsFilterIsActive], (items) => items);
