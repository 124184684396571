import i18next from "i18next";

import { ILanguageValueEnum, languageValueMapToIconName } from "../../types/localization";
import { ButtonWithoutDefaultStyling } from "../ButtonWithoutDefaultStyling";
import { Icon } from "../Icon";

import styles from "./LanguageIndicator.module.scss";

interface LanguageIndicatorProps {
  onClick?: () => void;
}

export const LanguageIndicator: React.FC<LanguageIndicatorProps> = ({ onClick }) => {
  const language = i18next.language as ILanguageValueEnum;

  return (
    <ButtonWithoutDefaultStyling onClick={onClick} className={styles.container}>
      <span className={styles.lang}>{language}</span>
      <Icon name={languageValueMapToIconName[language]} />
    </ButtonWithoutDefaultStyling>
  );
};
