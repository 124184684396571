import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Popover } from "@mui/material";

import { IOrdering } from "../../types/pagination";
import { CustomButton, IButtonVariant } from "../CustomButton";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./TableHeaderCell.module.scss";

interface IHeaderCellProps {
  name: string;
  field: string;
  ordering: string | null;
  isFilterActive: boolean;
  sortUpdateAction: (ordering: IOrdering) => void;
  selectFilters: (type: string) => JSX.Element | undefined;
  removeFilters: (field: string) => void;
}

export const TableHeaderCell: React.FC<IHeaderCellProps> = ({
  name,
  field,
  isFilterActive,
  ordering,
  selectFilters,
  sortUpdateAction,
  removeFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeFilters = () => {
    setAnchorEl(null);
  };

  const changeSort = () => {
    const newOrdering = {
      orderBy: field,
      descending: ordering ? ordering !== "desc" : false,
    };

    dispatch(sortUpdateAction(newOrdering));
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t(`${name}.${field}`)}</p>
      <div className={styles.filtersContainer}>
        <button className={styles.wrapperFilterIcon} id={id} onClick={openFilters}>
          {isFilterActive ? (
            <Icon name={IconNameEnum.filter} type={IconTypeEnum.grey} />
          ) : (
            <Icon name={IconNameEnum.filter} />
          )}
        </button>
        <button className={styles.wrapperFilterIcon} onClick={changeSort}>
          {ordering === "asc" && <Icon name={IconNameEnum.sortAsc} />}
          {ordering === "desc" && <Icon name={IconNameEnum.sortDesc} />}
          {!ordering && <Icon name={IconNameEnum.sortNone} />}
        </button>
      </div>
      <Popover
        className={styles.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeFilters}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <h1>{t(`${name}.${field}`)}</h1>
        <div className={styles.filters}>{selectFilters(field)}</div>
        <CustomButton
          variant={IButtonVariant.textImportant}
          className={styles.textSize}
          onClick={() => {
            dispatch(removeFilters(field));
          }}
          text={t("filters.cancel")}
        />
      </Popover>
    </div>
  );
};
