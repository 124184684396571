import { combineReducers } from "redux";

import { applicationReducer } from "./applicationReducer";
import { authReducer } from "./authReducer";
import { collectionYardReducer } from "./collectionYardReducer";
import { locationDetailReducer } from "./locationDetailReducer";
import { locationsReducer } from "./locationsReducer";
import { settingsReducer } from "./settingsReducer";
import { visitEditReducer } from "./visitEditReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  application: applicationReducer,
  collectionYard: collectionYardReducer,
  settings: settingsReducer,
  locations: locationsReducer,
  locationDetail: locationDetailReducer,
  visitEdit: visitEditReducer,
});
