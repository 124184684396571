import { IFilterItem, IFilters } from "./filters";
import { IOrdering, IPagination } from "./pagination";
import { ISearch } from "./search";
import { Status } from "./state";

export enum LocationsTabFilter {
  All = "all",
  Today = "today",
  Unfinished = "unfinished",
}

export interface ILocation {
  locationId: number;
  name: string;
  address: string;
  wcpid: number;
  lastVisit: string | null;
  lastUnfinishedVisit: string | null;
}

export type ILocations = ILocation[];

export interface ILocationsState {
  status: Status;
  requestId: string;
  errorMsg: string | null;
  locations: ILocations;
  isNextPageEnable: boolean;
  pagination: IPagination;
  ordering: IOrdering;
  filters: IFilters;
  search: ISearch;
  scrollTop: number;
  tabFilter: LocationsTabFilter;
}

export interface ILocationsListRequest {
  fulltext: string;
  pagination: IPagination;
  ordering: IOrdering;
  filters: IFilterItem[];
}
