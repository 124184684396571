import React from "react";

import styles from "./RoundedCheckbox.module.scss";

interface CheckboxProps {
  checked: boolean;
  onChange?: () => void;
}

export const RoundedCheckbox: React.FC<CheckboxProps> = ({ checked, onChange }) => {
  return <div className={`${styles.checkbox} ${checked ? styles.checked : styles.unchecked}`} onClick={onChange}></div>;
};
