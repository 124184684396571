import { isAnyOf } from "@reduxjs/toolkit";

import {
  getLocationDetailVisitsThunk,
  removeLocationDetailFilter,
  setLocationDetailFilter,
  setLocationDetailVisitsOrdering,
  setLocationDetailVisitsPagination,
} from "../../reducers/locationDetailReducer";
import { LOCATION_DETAIL_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { resetLocationDetail } from "./utils/resetLocationDetail";

export const locationDetailListener = (): void => {
  startAppListening({
    matcher: isAnyOf(setLocationDetailVisitsOrdering, setLocationDetailFilter, removeLocationDetailFilter),
    effect: async (_, { dispatch }) => {
      const pathname = location.pathname;

      switch (pathname) {
        case LOCATION_DETAIL_PAGE_PATH:
          resetLocationDetail(dispatch);
          break;
        default:
          break;
      }
    },
  });
  startAppListening({
    actionCreator: setLocationDetailVisitsPagination,
    effect: async (_, { dispatch }) => {
      dispatch(getLocationDetailVisitsThunk());
    },
  });
};
