import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { GET_COLLECTION_YARD_URL } from "../middleware/routes";
import { ICollectionYard, ICollectionYardApi, ICollectionYardState } from "../types/collectionYard";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";

export const initCollectionYardState: ICollectionYardState = {
  status: Status.idle,
  errorMsg: null,
  collectionYard: {
    name: "",
    address: "",
    wasteTypeIds: [],
  },
};

export const collectionYardSlice = createSlice({
  name: "collectionYard",
  initialState: initCollectionYardState,
  reducers: {
    cleanUpCollectionYard: (state) => {
      state.collectionYard = {
        name: "",
        address: "",
        wasteTypeIds: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCollectionYardThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(getCollectionYardThunk.fulfilled, (state, action) => {
        state.status = Status.success;
        state.errorMsg = null;

        state.collectionYard = action.payload;
      })
      .addCase(getCollectionYardThunk.rejected, (state) => {
        state.status = Status.error;
      });
  },
});

export const getCollectionYardThunk = createAsyncThunk<ICollectionYard>("user/getCollectionYardThunk", async () => {
  const response = await axiosRequestApi.get(GET_COLLECTION_YARD_URL);

  const data = response.data as ICollectionYardApi;

  const collectionYard: ICollectionYard = {
    name: data.name,
    address: data.address,
    wasteTypeIds: data.CollectionYardWasteTypes.map((item: any) => item.waste_type_id),
  };

  return collectionYard;
});

export const { cleanUpCollectionYard } = collectionYardSlice.actions;

export const collectionYardReducer = collectionYardSlice.reducer;
