import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CustomButton } from "../../../components/CustomButton";
import { Icon, IconNameEnum } from "../../../components/Icon";
import { PageHeaderContainer } from "../../../components/PageHeaderContainer";
import { HOME_PAGE_PATH, VISIT_EDIT_PAGE_PATH } from "../../../middleware/routes";
import { resetVisitEditState } from "../../../reducers/visitEditReducer";
import { getLocationDetailLocation, getLocationDetailLocationId } from "../../../selectors/locationDetail";
import { SearchParams } from "../../../types/searchParams";
import { getRouteUrlWithParams } from "../../../utils/route";

export const LocationDetailPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { name, address } = useSelector(getLocationDetailLocation);
  const locationId = useSelector(getLocationDetailLocationId);
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(resetVisitEditState());
    history.push(getRouteUrlWithParams(VISIT_EDIT_PAGE_PATH, { [SearchParams.LocationId]: locationId }));
  };

  return (
    <PageHeaderContainer
      title={name}
      subtitle={address}
      backTo={HOME_PAGE_PATH}
      RightComponent={
        <CustomButton text={t("newWaste")} startIcon={<Icon name={IconNameEnum.plus} />} onClick={handleButtonClick} />
      }
    />
  );
};
