import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { PageHeaderContainer } from "../../../components/PageHeaderContainer";
import { defaultSearch, setLocationsListSearch } from "../../../reducers/locationsReducer";
import { getLocationsSearch } from "../../../selectors/locations";

export const LocationsPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentSearch = useSelector(getLocationsSearch);
  const [search, setSearch] = useState(currentSearch.fulltext);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;

    if (!value && currentSearch.fulltext) {
      dispatch(setLocationsListSearch(defaultSearch));
    }
    setSearch(value);
  };

  const handleSearch = (): void => {
    dispatch(setLocationsListSearch({ fulltext: search }));
  };

  return (
    <PageHeaderContainer
      title={t("locations")}
      showSearchBar
      search={search}
      onChange={handleChange}
      onSearch={handleSearch}
    />
  );
};
