import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { CustomButton } from "../../../components/CustomButton";
import { showCustomToast } from "../../../components/CustomToast";
import { PageHeaderContainer } from "../../../components/PageHeaderContainer";
import { useHandleLeavePrompt } from "../../../hooks/useHandleLeavePrompt";
import { LOCATION_DETAIL_PAGE_PATH } from "../../../middleware/routes";
import {
  cleanUpLocationDetailVisits,
  getLocationDetailVisitsThunk,
  setLocationDetailLocationId,
} from "../../../reducers/locationDetailReducer";
import { cleanUpLocationsList, getLocationsListThunk } from "../../../reducers/locationsReducer";
import { saveVisitEdit } from "../../../reducers/visitEditReducer";
import { getLocationDetailLocation, getLocationDetailLocationId } from "../../../selectors/locationDetail";
import {
  getVisitEditCanSave,
  getVisitEditEvents,
  getVisitEditIsTouched,
  getVisitEditRemovedEventsIds,
  getVisitEditVisitId,
} from "../../../selectors/visitEdit";
import { SearchParams } from "../../../types/searchParams";
import { getRouteUrlWithParams } from "../../../utils/route";
import { LeaveConfirmationModal } from "../LeaveConfirmationModal/LeaveConfirmationModal";

import styles from "./VisitEditPageHeaderComponent.module.scss";

export const VisitEditPageHeaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const locationId = useSelector(getLocationDetailLocationId);
  const visitId = useSelector(getVisitEditVisitId);
  const events = useSelector(getVisitEditEvents);
  const removedEventsIds = useSelector(getVisitEditRemovedEventsIds);
  const canSave = useSelector(getVisitEditCanSave);
  const { name } = useSelector(getLocationDetailLocation);
  const currentDate = new Date().toLocaleDateString();

  const isTouched = useSelector(getVisitEditIsTouched);
  const saveButtonRef = useRef<React.RefObject<HTMLButtonElement>>();
  const { isModalVisible, toggleModalVisibility, handleContinue } = useHandleLeavePrompt(isTouched, [saveButtonRef]);
  const backPath = getRouteUrlWithParams(LOCATION_DETAIL_PAGE_PATH, { [SearchParams.LocationId]: locationId });

  const handleModalContinue = () => {
    handleContinue();
  };

  const handleSave: React.MouseEventHandler<HTMLButtonElement> = async () => {
    try {
      setIsUpdating(true);
      await saveVisitEdit(events, removedEventsIds, locationId, visitId);

      dispatch(cleanUpLocationDetailVisits());
      dispatch(setLocationDetailLocationId(Number(locationId)));
      dispatch(getLocationDetailVisitsThunk());
      dispatch(cleanUpLocationsList());
      dispatch(getLocationsListThunk());
      history.push(backPath);
    } catch (error) {
      showCustomToast(t("saveError"), { type: "error" });
    }
    setIsUpdating(false);
  };

  return (
    <>
      <PageHeaderContainer
        title={currentDate}
        subtitle={name}
        backTo={backPath}
        RightComponent={
          <CustomButton
            className={styles.saveBtn}
            text={t("save")}
            onClick={handleSave}
            ref={saveButtonRef}
            disabled={isUpdating || !canSave}
          />
        }
      />
      <LeaveConfirmationModal
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
        handleContinue={handleModalContinue}
      />
    </>
  );
};
