import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { setLocationsTabFilter } from "../../reducers/locationsReducer";
import { getLocationTabFilter } from "../../selectors/locations";
import { LocationsTabFilter } from "../../types/locations";

import styles from "./LocationsTabsFilter.module.scss";

export const LocationsTabsFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentTabFilter = useSelector(getLocationTabFilter);

  const handleClick = (value: LocationsTabFilter) => {
    dispatch(setLocationsTabFilter(value));
  };

  return (
    <div className={styles.container}>
      {Object.values(LocationsTabFilter).map((tab) => (
        <div
          key={tab}
          className={classNames(styles.tab, {
            [styles.active]: currentTabFilter === tab,
          })}
          onClick={() => handleClick(tab)}
        >
          {t(`locationsFilterTabs.${tab}`)}
        </div>
      ))}
    </div>
  );
};
