import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getCollectionYardWasteTypeIds } from "../../../selectors/collectionYard";
import { getVisitEditEventsWasteTypesIds } from "../../../selectors/visitEdit";
import { WasteTypeCard } from "../WasteTypeCard";

import styles from "./SelectWasteTypes.module.scss";

export const SelectWasteTypes: React.FC = () => {
  const { t } = useTranslation();
  const wasteTypesIds = useSelector(getCollectionYardWasteTypeIds);
  const selectedWasteTypesIds = useSelector(getVisitEditEventsWasteTypesIds);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{t("selectWasteTypes.chooseWasteType")}</h4>
      <div className={styles.containerTypes}>
        {wasteTypesIds.map((wasteId) => (
          <WasteTypeCard key={wasteId} wasteId={wasteId} isSelected={selectedWasteTypesIds.includes(wasteId)} />
        ))}
      </div>
    </div>
  );
};
