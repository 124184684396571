import { useTranslation } from "react-i18next";

import styles from "./LoginPageHeader.module.scss";

export const LoginPageHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p>
        <span className={styles.bold}>{t("welcome.hello")} </span>
        <span>{t("welcome.login")}</span>
      </p>
    </div>
  );
};
