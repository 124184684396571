import classNames from "classnames";

import styles from "./CircleContainer.module.scss";

export enum CircleContainerVariant {
  Primary = "primary",
  Secondary = "secondary",
}

interface CircleContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: CircleContainerVariant;
}

export const CircleContainer: React.FC<CircleContainerProps> = ({ children, variant, className, ...rest }) => {
  const compoundClassnames = classNames(styles.container, className, {
    [styles.secondary]: variant === CircleContainerVariant.Secondary,
  });

  return (
    <div className={compoundClassnames} {...rest}>
      {children}
    </div>
  );
};
