import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { CircleContainer } from "../../../components/CircleContainer";
import { Icon, IconNameEnum, IconTypeEnum } from "../../../components/Icon";
import { Toggle, ToggleVariant } from "../../../components/Toggle";
import { addVisitEditEvent, updateVisitEditUnfinished } from "../../../reducers/visitEditReducer";
import { IVisitEvent } from "../../../types/visitEdit";
import { WasteWeight } from "../WasteWeight";

import styles from "./WasteWeightsByWasteType.module.scss";

interface WasteWeightsByWasteTypeProps {
  wasteTypeId: number;
  events: IVisitEvent[];
}

export const WasteWeightsByWasteType: React.FC<WasteWeightsByWasteTypeProps> = ({ wasteTypeId, events }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAddEvent = () => {
    dispatch(addVisitEditEvent(wasteTypeId));
  };

  const handleUnfinishedChange = (unfinished: boolean) => {
    dispatch(updateVisitEditUnfinished({ wasteTypeId, unfinished }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <CircleContainer onClick={handleAddEvent}>
            <Icon name={IconNameEnum.plus} type={IconTypeEnum.black} />
          </CircleContainer>
          <h6 className={styles.wasteType}>{t(`enums.allWasteTypes.${wasteTypeId}`)}</h6>
        </div>
        <div className={styles.headerRight}>
          <p>{t(`wasteWeightsByWasteType.unfinished`)}</p>
          <Toggle
            checked={events[0].unfinished}
            onChange={(e) => handleUnfinishedChange(e.target.checked)}
            variant={ToggleVariant.Orange}
          />
        </div>
      </div>
      {events.map((event, i) => (
        <WasteWeight key={event.eventId} index={i} event={event} />
      ))}
    </div>
  );
};
