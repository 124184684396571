import React from "react";
import classNames from "classnames";

import styles from "./ButtonWithoutDefaultStyling.module.scss";

interface ButtonWithoutDefaultStylingProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export const ButtonWithoutDefaultStyling: React.FC<ButtonWithoutDefaultStylingProps> = ({
  children,
  className,
  ...props
}) => {
  const compoundClasses = classNames(styles.button, className);

  return (
    <button className={compoundClasses} {...props}>
      {children}
    </button>
  );
};
