import { Redirect, Switch } from "react-router-dom";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { LocationDetailPage } from "../../containers/LocationDetailPage";
import { LocationsPage } from "../../containers/LocationsPage";
import { VisitEditPage } from "../../containers/VisitEditPage";
import { HOME_PAGE_PATH, LOCATION_DETAIL_PAGE_PATH, VISIT_EDIT_PAGE_PATH } from "../../middleware/routes";
import { PrivateRoute } from "../PrivateRoute";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const AuthenticatedApp: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path={VISIT_EDIT_PAGE_PATH} component={VisitEditPage} />
      <PrivateRoute path={LOCATION_DETAIL_PAGE_PATH} component={LocationDetailPage} />
      <PrivateRoute exact path={HOME_PAGE_PATH} component={LocationsPage} />
      <Redirect to={HOME_PAGE_PATH} />
    </Switch>
  );
};
