import { WasteType } from "../utils/enums";
import { ICollectionPoint } from "./collectionPoint";
import { IFilter } from "./filter";
import { Job } from "./job";
import { ICollectionPointLocation } from "./location";
import { INotification } from "./notification";
import { IOrdering, IPagination } from "./pagination";
import { ISalvageYard } from "./salvageYard";
import { ISearch } from "./search";
import { ISettings } from "./settings";
import { IWagonEvent, IWagonEventCreate, IWagonEventDetail, WagonsFilter } from "./wagonEvent";
import {
  IDatePickerPin,
  IDateRange,
  IReportSummary,
  IWagonsJumpTo,
  IWagonsMapFeature,
  IWagonsMapFilters,
  IWagonsReport,
  IWagonsReportFilters,
} from "./wagons";
import { IWasteSummary, IWasteTypeRow } from "./wasteTypeQuantity";

export enum Status {
  idle = "idle",
  requesting = "requesting",
  requestingMore = "requestingMore",
  error = "error",
  success = "success",
  change = "change",
  postSuccess = "postSuccess",
}

export interface INotificationState {
  notification: INotification;
}

export interface IAuthState {
  status: Status;
  errorMsg: string | null;
  jwt: string;
}

export interface IJwtState {
  status: Status;
  errorMsg: string | null;
  jwt: string | null;
}

export interface ISettingsState {
  status: Status;
  errorMsg: string | null;
  settings: ISettings;
}

export interface IJobState {
  jobs: Job[];
}

export interface ICollectionPointsState {
  status: Status;
  requestId: string;
  errorMsg: string | null;
  locations: ICollectionPointLocation[];
  isNextPageEnable: boolean;
  pagination: IPagination;
  ordering: IOrdering;
  filter: IFilter;
  search: ISearch;
  scrollTop: number;
}

export enum DegreeOfSeparationFilterEnum {
  Low = 1,
  Medium,
  High,
  LowMedium,
  MediumHigh,
  LowHigh,
  All,
}

export interface ICollectionPointState {
  status: Status;
  errorMsg: string | null;
  collectionPoint: ICollectionPoint;
  pagination: IPagination;
  ordering: IOrdering;
  requestId: string;
  isNextPageEnable: boolean;
  scrollTop: number;
}

export interface ISalvageYardsState {
  status: Status;
  errorMsg: string | null;
  yards: ISalvageYard[];
  pagination: IPagination;
  ordering: IOrdering;
}

export interface ICollectionPointCreateState {
  status: Status;
  errorMsg: string | null;
  collectionPoint: ICollectionPoint | null;
}

export interface ILoaderState {
  isVisible: boolean;
}

export interface IWasteTypesQuantityState {
  status: Status;
  errorMsg: string | null;
  wasteSummaryDetail: IWasteSummary;
  wasteTypesSummaryRows: IWasteTypeRow[];
  updatedWasteSummaryId: number | null;
}

export interface IWagonsCalendarState {
  status: Status;
  requestId: string;
  errorMsg: string | null;
  wagonEvents: IWagonEvent[];
  isNextPageEnable: boolean;
  pagination: IPagination;
  filter: WagonsFilter;
  scrollTop: number;
}

export interface IWagonEventCreateState {
  status: Status;
  errorMsg: string | null;
  wagonEvent: IWagonEventCreate;
}

export interface IWagonEventDetailState {
  requestId: string;
  status: Status;
  errorMsg: string | null;
  wagonEvent: IWagonEventDetail;
}

export interface IWagonsDatePickerState {
  status: Status;
  errorMsg: string | null;
  datePickerPins: IDatePickerPin[];
  selectedDate: IDateRange;
  selectedWasteType: WasteType;
}

export interface IWagonsReportState {
  status: Status;
  errorMsg: string | null;
  report: IWagonsReport;
  map: IWagonsMapFeature[];
  filters: IWagonsReportFilters;
  mapView: boolean;
  jumpTo?: IWagonsJumpTo;
  mapFilters: IWagonsMapFilters;
  scrollTop: number;
  summary: IReportSummary;
  summaryDefault: IReportSummary;
}

export interface IWagonTableItemLookup {
  [key: number]: boolean;
}
