import { RootState } from "../../store";
import { FilterOperator } from "../../types/filters";
import { ILocationsListRequest, LocationsTabFilter } from "../../types/locations";
import { shouldRemoveLocalFilter } from "./localFilters";

export const adaptRequestBodyFroBackend = (state: RootState): ILocationsListRequest => {
  const locations = state.locations;
  const tabFilter = locations.tabFilter;

  const filters = locations.filters.items.filter((filter) => !shouldRemoveLocalFilter(filter));

  if (tabFilter === LocationsTabFilter.Today) {
    filters.push({
      id: "lastVisit",
      name: "lastVisit",
      value: new Date().toISOString().split("T")[0],
      operator: FilterOperator.IsGreaterOrEqualTo,
    });
  }
  if (tabFilter === LocationsTabFilter.Unfinished) {
    filters.push({
      id: "lastUnfinishedVisit",
      name: "lastUnfinishedVisit",
      value: new Date().toISOString().split("T")[0],
      operator: FilterOperator.IsGreaterOrEqualTo,
    });
  }

  return {
    fulltext: locations.search.fulltext,
    pagination: locations.pagination,
    ordering: locations.ordering,
    filters,
  };
};
