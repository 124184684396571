import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { GET_VISIT_URL, HOME_PAGE_PATH, POST_VISIT_URL } from "../middleware/routes";
import { RootState } from "../store";
import { Status } from "../types/state";
import { IVisitEdit, IVisitEditState, IVisitEvent } from "../types/visitEdit";
import { axiosRequestApi } from "../utils/axiosRequest";
import { isDateToday } from "../utils/dateHandler";
import { randomNumberId } from "../utils/id";
import { adaptRequestBodyFromBackend, adaptResponseBodyForFrontend } from "./utils/visitEditAdapter";

const initVisitEditState: IVisitEditState = {
  status: Status.idle,
  errorMsg: null,
  visitId: undefined,
  events: [],
  isTouched: false,
  removedEventsIds: [],
};

export const visitEditSlice = createSlice({
  name: "visitEdit",
  initialState: initVisitEditState,
  reducers: {
    setVisitEditId: (state, action) => {
      state.visitId = action.payload;
    },
    updateVisitEditEventBrutto: (state, action: PayloadAction<{ eventId: number; brutto: number | string }>) => {
      const eventIndex = state.events.findIndex((event) => event.eventId === action.payload.eventId);
      state.events[eventIndex].brutto = action.payload.brutto;

      state.isTouched = true;
    },
    updateVisitEditEventTara: (state, action: PayloadAction<{ eventId: number; tara: number | string }>) => {
      const eventIndex = state.events.findIndex((event) => event.eventId === action.payload.eventId);
      state.events[eventIndex].tara = action.payload.tara;

      state.isTouched = true;
    },
    updateVisitEditUnfinished: (state, action: PayloadAction<{ wasteTypeId: number; unfinished: boolean }>) => {
      state.events = state.events.map((event) => {
        if (event.wasteTypeId === action.payload.wasteTypeId) {
          event.unfinished = action.payload.unfinished;
        }
        return event;
      });

      state.isTouched = true;
    },
    addVisitEditEvent: (state, action: PayloadAction<number>) => {
      const unfinished = state.events.find((event) => event.wasteTypeId === action.payload)?.unfinished || false;

      state.events.push({
        eventId: randomNumberId(),
        wasteTypeId: action.payload,
        brutto: "",
        tara: "",
        unfinished,
      });

      state.isTouched = true;
    },
    removeVisitEditEvent: (state, action: PayloadAction<number>) => {
      const eventIndex = state.events.findIndex((event) => event.eventId === action.payload);

      if (state.events[eventIndex].apiEventId) {
        state.removedEventsIds.push(state.events[eventIndex].apiEventId as number);
      }

      state.events = state.events.filter((event) => event.eventId !== action.payload);
      state.isTouched = true;
    },
    resetVisitEditState: () => {
      return initVisitEditState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVisitThunk.pending, (state) => {
        state.status = Status.requesting;
      })
      .addCase(getVisitThunk.fulfilled, (state, action) => {
        if (!isDateToday(action.payload.created)) {
          window.location.href = HOME_PAGE_PATH;
          return;
        }

        state.errorMsg = null;
        state.status = Status.success;

        state.events = action.payload.events;
      })
      .addCase(getVisitThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
        window.location.href = HOME_PAGE_PATH;
      });
  },
});

export const getVisitThunk = createAsyncThunk<IVisitEdit, undefined, { state: RootState }>(
  "visitEdit/getVisitThunk",
  async (_, { getState }) => {
    const state = getState();

    const response = await axiosRequestApi.get(`${GET_VISIT_URL}/${state.visitEdit.visitId}`);
    return adaptResponseBodyForFrontend(response.data);
  }
);

export const saveVisitEdit = async (
  events: IVisitEvent[],
  removedEventsIds: number[],
  locationId: number,
  visitId?: number
) => {
  const requestBody = adaptRequestBodyFromBackend(events, removedEventsIds, locationId, visitId);

  return axiosRequestApi.post(POST_VISIT_URL, requestBody);
};

export const {
  setVisitEditId,
  updateVisitEditEventBrutto,
  updateVisitEditEventTara,
  updateVisitEditUnfinished,
  addVisitEditEvent,
  removeVisitEditEvent,
  resetVisitEditState,
} = visitEditSlice.actions;

export const visitEditReducer = visitEditSlice.reducer;
