import { maxDecimalPlacesForWeight, maxValueForWeight, minValueForWeight } from "../configs/weight";
import { roundToDecimalPlaces } from "./round";

export const getValidatedWeightValue = (value: string): string | number => {
  if (value === "") {
    return "";
  }

  const numberValue = Number(value);

  if (numberValue < minValueForWeight) {
    return minValueForWeight;
  }
  if (numberValue > maxValueForWeight) {
    return maxValueForWeight;
  }

  return roundToDecimalPlaces(numberValue, maxDecimalPlacesForWeight);
};
