import { useEffect } from "react";
import { useSelector } from "react-redux";

import { renewAuthTokenWithRefreshTokenThunk } from "../reducers/authReducer";
import { getJwtFromStore } from "../selectors/auth";
import { useAppDispatch } from "../store";

const authTokenExpireTime = 5 * 60 * 1000;
const rotationBuffer = 60 * 1000;
const rotationTime = authTokenExpireTime - rotationBuffer;

export const useRenewAuthTokenWithRefreshToken = (): void => {
  const dispatch = useAppDispatch();
  const jwt = useSelector(getJwtFromStore);

  useEffect(() => {
    if (!jwt) {
      return;
    }

    const timeout = setTimeout(() => {
      dispatch(renewAuthTokenWithRefreshTokenThunk());
    }, rotationTime);

    return () => clearTimeout(timeout);
  }, [jwt]);
};
