import {
  cleanUpLocationsList,
  defaultPagination,
  setLocationsListPagination,
} from "../../../reducers/locationsReducer";
import { AppDispatch } from "../../../store";

export const resetLocations = (dispatch: AppDispatch): void => {
  dispatch(cleanUpLocationsList());
  dispatch(setLocationsListPagination(defaultPagination));
};
