import { PageContainer } from "../../components/PageContainer";
import { SelectWasteTypes } from "./SelectWasteTypes/SelectWasteTypes";
import { VisitEditPageHeaderComponent } from "./VisitEditPageHeaderComponent";
import { WasteWeights } from "./WasteWeights";

import styles from "./VisitEditPage.module.scss";

export const VisitEditPage: React.FC = () => {
  return (
    <PageContainer HeaderComponent={VisitEditPageHeaderComponent} maxFullScreen={false} fullHeight={false}>
      <div className={styles.container}>
        <SelectWasteTypes />
        <WasteWeights />
      </div>
    </PageContainer>
  );
};
