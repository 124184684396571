import { getModuleClassNames } from "../../utils/getModuleClassNames";
import { PageFooter } from "../PageFooter";

import styles from "./PageContainer.module.scss";

interface PageContainerProps {
  children: React.ReactNode;
  HeaderComponent?: React.FC;
  fullHeight?: boolean;
  fullScreen?: boolean;
  maxFullScreen?: boolean;
  displayPremium?: boolean;
  showFooter?: boolean;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  HeaderComponent,
  fullHeight = true,
  fullScreen = false,
  maxFullScreen = true,
  showFooter = true,
}) => {
  const appliedPageContainerClassNamesArray = [styles.pageContainer];
  const appliedPageContentClassNamesArray = [styles.pageContent];

  if (fullHeight) {
    appliedPageContentClassNamesArray.push(styles.fullHeight);
  }
  if (fullScreen) {
    appliedPageContainerClassNamesArray.push(styles.fullScreen);
  }
  if (maxFullScreen) {
    appliedPageContentClassNamesArray.push(styles.maxFullScreen);
  }

  return (
    <div className={getModuleClassNames(appliedPageContainerClassNamesArray)}>
      {HeaderComponent && <HeaderComponent />}
      <div className={getModuleClassNames(appliedPageContentClassNamesArray)}>{children}</div>
      {showFooter && <PageFooter />}
    </div>
  );
};
