import { useSelector } from "react-redux";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";

import { DateFilter } from "../../../components/TableFilters/DateFilter";
import { NumberFilter } from "../../../components/TableFilters/NumberFilter";
import { TableHeaderCell } from "../../../components/TableHeaderCell";
import {
  removeLocationDetailFilter,
  setLocationDetailFilter,
  setLocationDetailVisitsOrdering,
} from "../../../reducers/locationDetailReducer";
import {
  getLocationDetailFilterIsActive,
  getLocationDetailFilterValue,
  getLocationDetailOrderingValue,
} from "../../../selectors/locationDetail";
import { RootState } from "../../../store";
import { ColumnType } from "./locationDetailTableColumnTypes";

interface IHeaderCellProps {
  params: GridColumnHeaderParams;
}

const selectFilters = (type: string) => {
  switch (type) {
    case ColumnType.created:
      return (
        <DateFilter
          fieldName={type}
          filterSelectorValue={getLocationDetailFilterValue}
          filterUpdateAction={setLocationDetailFilter}
        />
      );
    case ColumnType.wasteSum:
      return (
        <NumberFilter
          fieldName={type}
          filterSelectorValue={getLocationDetailFilterValue}
          filterUpdateAction={setLocationDetailFilter}
        />
      );
    default:
      <div />;
  }
};

export const LocationDetailTableHeaderCell: React.FC<IHeaderCellProps> = ({ params }) => {
  const ordering = useSelector((state: RootState) => getLocationDetailOrderingValue(state, params.field));
  const isFilterActive = useSelector((state: RootState) => getLocationDetailFilterIsActive(state, params.field));

  return (
    <TableHeaderCell
      name="locationDetailVisits"
      field={params.field}
      ordering={ordering}
      isFilterActive={isFilterActive}
      sortUpdateAction={setLocationDetailVisitsOrdering}
      selectFilters={selectFilters}
      removeFilters={removeLocationDetailFilter}
    />
  );
};
