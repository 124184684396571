import { snakeCase } from "lodash";

import { RootState } from "../../store";
import { ILocationDetail, ILocationDetailApi, ILocationDetailVisitsRequest } from "../../types/locationDetail";
import { shouldRemoveLocalFilter } from "./localFilters";

export const adaptRequestBodyFroBackend = (state: RootState): ILocationDetailVisitsRequest => {
  const locationDetail = state.locationDetail;
  return {
    location_id: locationDetail.locationId,
    pagination: locationDetail.pagination,
    ordering: { ...locationDetail.ordering, orderBy: snakeCase(locationDetail.ordering.orderBy) },
    filters: locationDetail.filters.items
      .filter((filter) => !shouldRemoveLocalFilter(filter))
      .map((filter) => ({ ...filter, name: snakeCase(filter.name) })),
  };
};

export const adaptResponseBodyForFrontend = (responseBody: ILocationDetailApi): ILocationDetail => {
  return {
    location: {
      name: responseBody.location.name,
      address: responseBody.location.address,
    },
    visits: responseBody.visits.map((apiVisit) => ({
      collectionYardVisitId: apiVisit.collection_yard_visit_id,
      wasteTypes: apiVisit.waste_types,
      wasteSum: apiVisit.waste_sum,
      created: apiVisit.created,
      unfinished: apiVisit.unfinished,
    })),
  };
};
