import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TextInput } from "../../components/TextInput";
import { RootState } from "../../store";
import { FilterOperator, IFilterItem } from "../../types/filters";

interface Props {
  fieldName: string;
  filterSelectorValue: (state: RootState, fieldName: string) => IFilterItem | undefined;
  filterUpdateAction: (item: IFilterItem) => void;
}

export const NumberFilter: React.FC<Props> = ({ fieldName, filterSelectorValue, filterUpdateAction }) => {
  const minValueId = `${fieldName}Min`;
  const maxValueId = `${fieldName}Max`;
  const minValue = useSelector((state: RootState) => filterSelectorValue(state, minValueId));
  const maxValue = useSelector((state: RootState) => filterSelectorValue(state, maxValueId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        placeHolder={t(`filters.${fieldName}From`)}
        type="number"
        value={String(minValue?.value)}
        onChange={(e) => {
          dispatch(
            filterUpdateAction({
              id: minValueId,
              name: fieldName,
              operator: FilterOperator.IsGreaterOrEqualTo,
              value: e.target.value,
            })
          );
        }}
      />
      <TextInput
        placeHolder={t(`filters.${fieldName}To`)}
        type="number"
        value={String(maxValue?.value)}
        onChange={(e) => {
          dispatch(
            filterUpdateAction({
              id: maxValueId,
              name: fieldName,
              operator: FilterOperator.IsLessOrEqualTo,
              value: e.target.value,
            })
          );
        }}
      />
    </>
  );
};
