import { BridgeAction, BridgeMessageType } from "../types/bridge";

export const triggerBridgeAction = (action: BridgeAction) => {
  if (!window.ReactObserver) {
    console.warn("ReactObserver is not available");
    return;
  }

  window.ReactObserver.postMessage(JSON.stringify({ action }));
};

export const handleBridgeMessage = (
  event: MessageEvent,
  messageType: BridgeMessageType,
  setResult: (data: string) => void
) => {
  try {
    const message = JSON.parse(event?.data);

    if (message?.type === messageType) {
      setResult(message.data);
    }
  } catch (error) {
    console.error("Error parsing message:", error);
  }
};
