import classNames from "classnames";

import styles from "./Toggle.module.scss";

export enum ToggleVariant {
  Green = "green",
  Orange = "orange",
}

interface ToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: ToggleVariant;
}

export const Toggle: React.FC<ToggleProps> = ({ variant = ToggleVariant.Green, children, ...rest }) => {
  return (
    <label className={styles.toggle}>
      <input className={classNames(styles.toggleInput, styles[variant])} type="checkbox" {...rest} />
      <span className={styles.slider} />
    </label>
  );
};
