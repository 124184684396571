export const getWeighValue = (doesWeigh: boolean, value: any, unit?: string, decimalPlaces?: number) => {
  if (typeof value === "string") value = parseFloat(value);

  if (doesWeigh && !isNaN(value)) {
    if (decimalPlaces !== undefined && decimalPlaces >= 0) {
      const factor = Math.pow(10, decimalPlaces);
      value = Math.round(value * factor) / factor;
    }
  }

  if (unit) value = value + ` ${unit}`;
  if (doesWeigh) return value;
  return "N/A";
};
