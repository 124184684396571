import { useSelector } from "react-redux";

import { getApplicationModeState } from "../../selectors/application";
import { classHandler } from "../../utils/classHandler";
import { getModuleClassNames } from "../../utils/getModuleClassNames";

import styles from "./Layout.module.scss";

export const Layout: React.FC = ({ children }) => {
  const applicationMode = useSelector(getApplicationModeState);

  return (
    <div className={getModuleClassNames(classHandler(styles, "layout", applicationMode))}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};
