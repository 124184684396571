import {
  cleanUpLocationDetailVisits,
  defaultPagination,
  setLocationDetailVisitsPagination,
} from "../../../reducers/locationDetailReducer";
import { AppDispatch } from "../../../store";

export const resetLocationDetail = (dispatch: AppDispatch): void => {
  dispatch(cleanUpLocationDetailVisits());
  dispatch(setLocationDetailVisitsPagination(defaultPagination));
};
