import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { initAuthTokenWithRefreshToken } from "../reducers/authReducer";
import { getJwtFromStore } from "../selectors/auth";

export const useLoadUser = (): void => {
  const dispatch = useDispatch();
  const jwt = useSelector(getJwtFromStore);

  useEffect(() => {
    if (jwt) {
      dispatch(initAuthTokenWithRefreshToken());
    }
  }, []);
};
