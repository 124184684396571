import type { TypedStartListening } from "@reduxjs/toolkit";
import { createListenerMiddleware } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "../../store";
import { initialDataLoadListener } from "./initialDataLoadListener";
import { invalidRefreshTokenListener } from "./invalidRefreshTokenListener";
import { locationDetailListener } from "./locationDetailListener";
import { locationsListener } from "./locationsListener";
import { logoutListener } from "./logoutListener";

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
// Create the middleware instance and methods
export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const indexListener = (): void => {
  initialDataLoadListener();
  invalidRefreshTokenListener();
  logoutListener();
  locationsListener();
  locationDetailListener();
};
