import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

export const getVisitEdit = (state: RootState) => state.visitEdit;
export const getVisitEditVisitId = (state: RootState) => state.visitEdit.visitId;

export const getVisitEditVisitEventsStatus = (state: RootState) => state.visitEdit.status;
export const getVisitEditVisitEventsErrorMSg = (state: RootState) => state.visitEdit.errorMsg;

export const getVisitEditEvents = (state: RootState) => state.visitEdit.events;
export const getVisitEditRemovedEventsIds = (state: RootState) => state.visitEdit.removedEventsIds;

export const getVisitEditEventsWasteTypesIds = createSelector([getVisitEditEvents], (events) => {
  return events.map((event) => event.wasteTypeId);
});

export const getVisitEditIsTouched = (state: RootState) => state.visitEdit.isTouched;

export const getVisitEditCanSave = createSelector([getVisitEditEvents, getVisitEditIsTouched], (events, isTouched) => {
  if (!isTouched) return false;

  const notInUnfinishedEvents = events.filter((event) => !event.unfinished);
  return notInUnfinishedEvents.every((event) => Number(event.brutto) >= Number(event.tara));
});

export const getEventsGroupedByWasteType = createSelector([getVisitEditEvents], (events) => {
  return events.reduce((acc: { [key: number]: typeof events }, event) => {
    if (!acc[event.wasteTypeId]) {
      acc[event.wasteTypeId] = [];
    }

    acc[event.wasteTypeId].push(event);

    return acc;
  }, {});
});
