import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GridEventListener, GridEvents, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";

import { VISIT_EDIT_PAGE_PATH } from "../../../middleware/routes";
import { defaultOrdering } from "../../../reducers/locationsReducer";
import { setLocationsListOrdering } from "../../../reducers/locationsReducer";
import { getVisitThunk, resetVisitEditState, setVisitEditId } from "../../../reducers/visitEditReducer";
import { getLocationDetailLocationId } from "../../../selectors/locationDetail";
import { getIsLocationsListStatusLoading, getLocationsOrdering } from "../../../selectors/locations";
import { ILocationDetailVisit } from "../../../types/locationDetail";
import { SearchParams } from "../../../types/searchParams";
import { isDateToday } from "../../../utils/dateHandler";
import { getRouteUrlWithParams } from "../../../utils/route";

interface LocationsTableHandlers {
  handleAdditionalRowClick: GridEventListener<GridEvents.rowClick>;
  handleSortModelChange: (model: GridSortModel) => void;
}

export const useGetLocationDetailTableHandlers = (): LocationsTableHandlers => {
  const dispatch = useDispatch();
  const ordering = useSelector(getLocationsOrdering);
  const isLoading = useSelector(getIsLocationsListStatusLoading);
  const history = useHistory();
  const locationId = useSelector(getLocationDetailLocationId);

  const handleAdditionalRowClick: GridEventListener<GridEvents.rowClick> = (
    params: GridRowParams<ILocationDetailVisit>
  ) => {
    if (!isDateToday(params.row.created)) return;

    const visitId = params.row.collectionYardVisitId;

    dispatch(resetVisitEditState());
    dispatch(setVisitEditId(visitId));
    dispatch(getVisitThunk());

    history.push(
      getRouteUrlWithParams(VISIT_EDIT_PAGE_PATH, {
        [SearchParams.LocationId]: locationId,
        [SearchParams.VisitId]: visitId,
      })
    );
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (isLoading) {
      return;
    }

    const field = model[0].field;
    const newOrderBy = () => {
      switch (field) {
        default:
          return field;
      }
    };

    const newOrdering = {
      orderBy: newOrderBy(),
      descending: ordering.orderBy === newOrderBy() ? !ordering.descending : defaultOrdering.descending,
    };
    dispatch(setLocationsListOrdering(newOrdering));
  };

  const locationsTableHandlers: LocationsTableHandlers = {
    handleAdditionalRowClick,
    handleSortModelChange,
  };

  return locationsTableHandlers;
};
