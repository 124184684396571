import "react-toastify/dist/ReactToastify.css";

import { toast, ToastOptions } from "react-toastify";

import styles from "./CustomToast.module.scss";

export const showCustomToast = (message: string, options?: ToastOptions) => {
  toast(message, {
    className: styles.customToast,
    bodyClassName: styles.customToastBody,
    closeButton: true,
    autoClose: 10_000,
    position: "top-center",
    ...options,
  });
};
