import { useSelector } from "react-redux";

import { getCollectionYardName } from "../../selectors/collectionYard";
import { Icon, IconNameEnum } from "../Icon";

import styles from "./PageFooter.module.scss";

export const PageFooter: React.FC = () => {
  const collectionYardName = useSelector(getCollectionYardName);

  return (
    <div className={styles.container}>
      <div className={styles.nameContainer}>
        <h6 className={styles.name}>{collectionYardName}</h6>
        <p className={styles.by}>by WAMA</p>
      </div>
      <Icon name={IconNameEnum.logo} className={styles.logo} />
    </div>
  );
};

export default PageFooter;
