import { resetApplicationState } from "../../../reducers/applicationReducer";
import { logoutUserThunk } from "../../../reducers/authReducer";
import { resetLocationDetailState } from "../../../reducers/locationDetailReducer";
import { resetLocationsState } from "../../../reducers/locationsReducer";
import { resetSettingsState } from "../../../reducers/settingsReducer";
import { resetVisitEditState } from "../../../reducers/visitEditReducer";
import { AppDispatch } from "../../../store";

export const logoutUser = (dispatch: AppDispatch): void => {
  dispatch(logoutUserThunk());
  dispatch(resetApplicationState());
  dispatch(resetSettingsState());
  dispatch(resetLocationsState());
  dispatch(resetLocationDetailState());
  dispatch(resetVisitEditState());
};
