import { useSelector } from "react-redux";
import { GridColumnHeaderParams } from "@mui/x-data-grid-pro";

import { DateFilter } from "../../../components/TableFilters/DateFilter";
import { NumberFilter } from "../../../components/TableFilters/NumberFilter";
import { TextFilter } from "../../../components/TableFilters/TextFilter";
import { TableHeaderCell } from "../../../components/TableHeaderCell/TableHeaderCell";
import {
  removeLocationsFilter,
  setLocationsFilter,
  setLocationsListOrdering,
} from "../../../reducers/locationsReducer";
import {
  getLocationsFilterIsActive,
  getLocationsFilterValue,
  getLocationsOrderingValue,
} from "../../../selectors/locations";
import { RootState } from "../../../store";
import { ColumnType } from "./locationsTableColumnTypes";

interface IHeaderCellProps {
  params: GridColumnHeaderParams;
}

const selectFilters = (type: string) => {
  switch (type) {
    case ColumnType.name:
      return (
        <TextFilter
          fieldName={type}
          filterSelectorValue={getLocationsFilterValue}
          filterUpdateAction={setLocationsFilter}
        />
      );
    case ColumnType.address:
      return (
        <TextFilter
          fieldName={type}
          filterSelectorValue={getLocationsFilterValue}
          filterUpdateAction={setLocationsFilter}
        />
      );
    case ColumnType.lastVisit:
      return (
        <DateFilter
          fieldName={type}
          filterSelectorValue={getLocationsFilterValue}
          filterUpdateAction={setLocationsFilter}
        />
      );
    case ColumnType.wcpid:
      return (
        <NumberFilter
          fieldName={type}
          filterSelectorValue={getLocationsFilterValue}
          filterUpdateAction={setLocationsFilter}
        />
      );
    default:
      <div />;
  }
};

export const LocationsTableHeaderCell: React.FC<IHeaderCellProps> = ({ params }) => {
  const ordering = useSelector((state: RootState) => getLocationsOrderingValue(state, params.field));
  const isFilterActive = useSelector((state: RootState) => getLocationsFilterIsActive(state, params.field));

  return (
    <TableHeaderCell
      name="locationsList"
      field={params.field}
      ordering={ordering}
      isFilterActive={isFilterActive}
      sortUpdateAction={setLocationsListOrdering}
      selectFilters={selectFilters}
      removeFilters={removeLocationsFilter}
    />
  );
};
