import { isAnyOf } from "@reduxjs/toolkit";

import { setLoaderVisibility } from "../../reducers/applicationReducer";
import { initAuthTokenWithRefreshToken, loginUserThunk } from "../../reducers/authReducer";
import { getCollectionYardThunk } from "../../reducers/collectionYardReducer";
import { getLocationDetailVisitsThunk, setLocationDetailLocationId } from "../../reducers/locationDetailReducer";
import { getLocationsListThunk } from "../../reducers/locationsReducer";
import { getVisitThunk, setVisitEditId } from "../../reducers/visitEditReducer";
import { SearchParams } from "../../types/searchParams";
import { getQueryString } from "../../utils/getQueryString";
import { HOME_PAGE_PATH, LOCATION_DETAIL_PAGE_PATH, VISIT_EDIT_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { getPromiseAllArray } from "./utils/getPromiseAllArray";

export const initialDataLoadListener = (): void => {
  startAppListening({
    matcher: isAnyOf(loginUserThunk.fulfilled, initAuthTokenWithRefreshToken.fulfilled),
    effect: async (_, { dispatch }) => {
      const pathname = location.pathname;

      dispatch(setLoaderVisibility(true));
      dispatch(getLocationsListThunk());

      const actionsArray: any[] = [getCollectionYardThunk()];

      if (pathname === LOCATION_DETAIL_PAGE_PATH || pathname === VISIT_EDIT_PAGE_PATH) {
        const locationId = getQueryString(SearchParams.LocationId);

        if (locationId === null || locationId === "") {
          window.location.href = HOME_PAGE_PATH;
        }

        dispatch(setLocationDetailLocationId(Number(locationId)));
        actionsArray.push(getLocationDetailVisitsThunk());
      }

      if (pathname === VISIT_EDIT_PAGE_PATH) {
        const visitId = getQueryString(SearchParams.VisitId);

        if (visitId !== null && visitId !== "") {
          dispatch(setVisitEditId(Number(visitId)));
          actionsArray.push(getVisitThunk());
        }
      }

      const promiseAllArray = await getPromiseAllArray(actionsArray, dispatch);

      const isAllActionsFulfilled = promiseAllArray.every((action: any) => action.meta.requestStatus === "fulfilled");
      if (isAllActionsFulfilled) {
        dispatch(setLoaderVisibility(false));
      }
    },
  });
};
