export enum FilterLinkOperator {
  And = "and",
  Or = "or",
}

export enum FilterOperator {
  Contains = "contains",
  IsAnyOf = "isAnyOf",
  IsEmpty = "isEmpty",
  IsGreaterOrEqualTo = "isGreaterOrEqualTo",
  IsLessOrEqualTo = "isLessOrEqualTo",
  ArrayContains = "arrayContains",
  IsEqualTo = "isEqualTo",
}

export interface IFilterItem {
  id: string;
  name: string;
  operator: FilterOperator;
  value?: any;
}

export interface IFilters {
  items: IFilterItem[];
  linkOperator: FilterLinkOperator;
}
