import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import { maxDecimalPlacesForWeight } from "../../configs/weight";
import { Weight } from "../../types/weight";
import { getWeighValue } from "../../utils/collectionPoints/getWeighValue";
import { roundToDecimalPlaces } from "../../utils/round";

interface ITableWeightCellProps {
  params: GridRenderCellParams<number>;
}

export const TableWeightCell: React.FC<ITableWeightCellProps> = ({ params }) => {
  const weight = params.value ? roundToDecimalPlaces(params.value, maxDecimalPlacesForWeight) : 0;
  const weightDisplay = getWeighValue(true, weight, Weight.Kg);

  return <p>{weightDisplay}</p>;
};
