import { PageContainer } from "../../components/PageContainer";
import { LocationDetailPageHeaderComponent } from "./LocationDetailPageHeaderComponent";
import { LocationDetailTable } from "./LocationDetailTable";

import styles from "./LocationDetailPage.module.scss";

export const LocationDetailPage: React.FC = () => {
  return (
    <PageContainer HeaderComponent={LocationDetailPageHeaderComponent}>
      <div className={styles.content}>
        <LocationDetailTable />
      </div>
    </PageContainer>
  );
};
