import { isAnyOf } from "@reduxjs/toolkit";

import { initAuthTokenWithRefreshToken, renewAuthTokenWithRefreshTokenThunk } from "../../reducers/authReducer";
import { startAppListening } from "./listenerMiddleware";
import { logoutUser } from "./utils/logoutUser";

export const invalidRefreshTokenListener = () => {
  startAppListening({
    matcher: isAnyOf(initAuthTokenWithRefreshToken.rejected, renewAuthTokenWithRefreshTokenThunk.rejected),
    effect: async (_, { dispatch }) => {
      logoutUser(dispatch);
    },
  });
};
