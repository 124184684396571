import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { CircleContainer, CircleContainerVariant } from "../../../components/CircleContainer";
import { Icon, IconNameEnum, IconTypeEnum } from "../../../components/Icon";
import { TextInput } from "../../../components/TextInput";
import { maxDecimalPlacesForWeight, maxValueForWeight, minValueForWeight } from "../../../configs/weight";
import {
  removeVisitEditEvent,
  updateVisitEditEventBrutto,
  updateVisitEditEventTara,
} from "../../../reducers/visitEditReducer";
import { IVisitEvent } from "../../../types/visitEdit";
import { Weight } from "../../../types/weight";
import { getWeighValue } from "../../../utils/collectionPoints/getWeighValue";
import { getValidatedWeightValue } from "../../../utils/input";

import styles from "./WasteWeight.module.scss";

interface WasteWeightProps {
  index: number;
  event: IVisitEvent;
}

export const WasteWeight: React.FC<WasteWeightProps> = ({ index, event }) => {
  const { eventId, brutto, tara, unfinished } = event;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const hasError = !unfinished && tara && Number(brutto) < Number(tara);

  const InputEndItem = <div className={styles.unit}>{Weight.Kg}</div>;
  const step = Math.pow(10, -maxDecimalPlacesForWeight);

  return (
    <div className={classNames(styles.wrapper)}>
      <div />
      <label className={styles.label}>{t("brutto")}</label>
      <label className={styles.label}>{t("tara")}</label>
      <label className={classNames(styles.label, styles.primaryLabel, { [styles.textError]: hasError })}>
        {t("netto")}
      </label>
      <div />
      <CircleContainer variant={CircleContainerVariant.Secondary}>{index + 1}</CircleContainer>
      <TextInput
        type="number"
        placeHolder={t("brutto")}
        value={brutto}
        min={minValueForWeight}
        max={maxValueForWeight}
        step={step}
        endItem={InputEndItem}
        onChange={(e) =>
          dispatch(updateVisitEditEventBrutto({ eventId, brutto: getValidatedWeightValue(e.target.value) }))
        }
      />
      <TextInput
        type="number"
        placeHolder={t("tara")}
        value={tara}
        min={minValueForWeight}
        max={maxValueForWeight}
        step={step}
        endItem={InputEndItem}
        onChange={(e) => dispatch(updateVisitEditEventTara({ eventId, tara: getValidatedWeightValue(e.target.value) }))}
      />
      <div className={classNames(styles.weight, { [styles.error]: hasError })}>
        {getWeighValue(true, Number(brutto) - Number(tara), Weight.Kg, maxDecimalPlacesForWeight)}
      </div>
      <CircleContainer onClick={() => dispatch(removeVisitEditEvent(eventId))} className={styles.marginLeft}>
        <Icon name={IconNameEnum.trash} type={IconTypeEnum.red} />
      </CircleContainer>
    </div>
  );
};
