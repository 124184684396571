export enum IconNameEnum {
  arrowBack = "arrowBack",
  arrowDown = "arrowDown",
  arrows = "arrows",
  arrowUp = "arrowUp",
  bank = "bank",
  bin = "bin",
  calendar = "calendar",
  camera = "camera",
  check = "check",
  chevron = "chevron",
  chevronRight = "chevronRight",
  clock = "clock",
  cross = "cross",
  delete = "delete",
  exclamation = "exclamation",
  filter = "filter",
  flag = "flag",
  flagBritain = "flagBritain",
  flagCzech = "flagCzech",
  flagGermany = "flagGermany",
  flagHungary = "flagHungary",
  flagSlovakia = "flagSlovakia",
  globe = "globe",
  helpdeskMessage = "helpdeskMessage",
  home = "home",
  loader = "loader",
  location = "location",
  logo = "logo",
  logoText = "logoText",
  logout = "logout",
  mail = "mail",
  note = "note",
  paper = "paper",
  paperclip = "paperclip",
  password = "password",
  pencil = "pencil",
  plus = "plus",
  preview = "preview",
  report = "report",
  rfid = "rfid",
  scanQR = "scanQR",
  search = "search",
  sortAsc = "sortAsc",
  sortDesc = "sortDesc",
  sortNone = "sortNone",
  stat = "stat",
  trash = "trash",
  truck = "truck",
  username = "username",
  warning = "warning",
  weight = "weight",
}
