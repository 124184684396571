import { useTranslation } from "react-i18next";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DotWrapper, DotWrapperVariant } from "../../../components/DotWrapper";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { TableCellWithRightArrow } from "../../../components/TableCellWithRightArrow";
import { TableDateCell } from "../../../components/TableDateCell";
import { TableWeightCell } from "../../../components/TableWeightCell";
import { isDateToday } from "../../../utils/dateHandler";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";
import { columnLabels, ColumnType } from "./locationDetailTableColumnTypes";
import { LocationDetailTableHeaderCell } from "./LocationDetailTableHeaderCell";

export const useGetLocationDetailTableColumns = () => {
  const { t } = useTranslation();
  const { getFlex } = getTableColumnUtils();

  const columns: GridColDef[] = columnLabels.map((label) => {
    const columnObject: GridColDef = {
      field: label,
      headerName: t(label),
      type: "string",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <LocationDetailTableHeaderCell params={params} />;
      },
      flex: getFlex(label, []),
    };

    columnObject.renderCell = (params: GridRenderCellParams) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case ColumnType.created: {
          const cellContent = <TableDateCell params={params} />;
          if (params.row.unfinished && isDateToday(params.row.created)) {
            return <DotWrapper variant={DotWrapperVariant.Orange}>{cellContent}</DotWrapper>;
          }

          return cellContent;
        }
        case ColumnType.wasteSum: {
          if (isDateToday(params.row.created))
            return <TableCellWithRightArrow mainComponent={<TableWeightCell params={params} />} />;
          return <TableWeightCell params={params} />;
        }

        default:
          break;
      }
    };

    return columnObject;
  });

  return columns;
};
