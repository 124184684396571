import { createSelector } from "@reduxjs/toolkit";

import { shouldRemoveLocalFilter } from "../reducers/utils/localFilters";
import { RootState } from "../store";
import { Status } from "../types/state";

export const getLocationDetail = (state: RootState) => state.locationDetail;
export const getLocationDetailLocationId = (state: RootState) => state.locationDetail.locationId;
export const getLocationDetailVisitStatus = (state: RootState) => state.locationDetail.status;
export const getIsLocationDetailVisitStatusLoading = (state: RootState) =>
  state.locationDetail.status !== Status.success;

export const getLocationDetailVisitErrorMsg = (state: RootState) => state.locationDetail.errorMsg;

export const getLocationDetailLocation = (state: RootState) => state.locationDetail.location;
export const getLocationDetailVisits = (state: RootState) => state.locationDetail.visits;

export const getLocationDetailNextPageState = (state: RootState) => state.locationDetail.isNextPageEnable;

export const getLocationDetailPagination = (state: RootState) => state.locationDetail.pagination;
export const getLocationDetailOrdering = (state: RootState) => state.locationDetail.ordering;
export const getLocationDetailFilters = (state: RootState) => state.locationDetail.filters;
export const getLocationDetailFiltersItems = (state: RootState) => state.locationDetail.filters.items;

const getterLocationDetailFilterValue = (state: RootState, filterId: string) =>
  state.locationDetail.filters.items.find((item) => item.id === filterId);
export const getLocationDetailFilterValue = createSelector([getterLocationDetailFilterValue], (items) => items);

export const getLocationDetailScrollTop = (state: RootState) => state.locationDetail.scrollTop;

export const getLocationDetailOrderingValue = (state: RootState, fieldName: string) =>
  fieldName.toLowerCase() === state.locationDetail.ordering.orderBy.toLowerCase()
    ? state.locationDetail.ordering.descending
      ? "desc"
      : "asc"
    : null;

const getterLocationDetailFilterIsActive = (state: RootState, fieldName: string) =>
  !!state.locationDetail.filters.items.find(
    (item) => String(item.id).startsWith(fieldName) && !shouldRemoveLocalFilter(item)
  );

export const getLocationDetailFilterIsActive = createSelector([getterLocationDetailFilterIsActive], (items) => items);
