import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { HOME_PAGE_PATH, POST_LOCATION_DETAIL_VISITS_URL, POST_QR_CODE_VERIFY_URL } from "../middleware/routes";
import { RootState } from "../store";
import { FilterLinkOperator, IFilterItem, IFilters } from "../types/filters";
import {
  ILocationDetail,
  ILocationDetailLocation,
  ILocationDetailState,
  ILocationDetailVisit,
} from "../types/locationDetail";
import { IOrdering, IPagination } from "../types/pagination";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { adaptRequestBodyFroBackend, adaptResponseBodyForFrontend } from "./utils/locationDetailAdapter";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "created",
  descending: true,
};

const defaultVisit: ILocationDetailVisit = {
  collectionYardVisitId: 0,
  wasteTypes: [],
  wasteSum: 0,
  created: "",
  unfinished: false,
};

const defaultVisits: ILocationDetailVisit[] = getSkeletonMockedArray(defaultVisit, 50, 0);

const defaultFilters: IFilters = {
  items: [],
  linkOperator: FilterLinkOperator.And,
};

const defaultLocation: ILocationDetailLocation = {
  name: "",
  address: "",
};

const initLocationDetailState: ILocationDetailState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  locationId: 0,
  location: defaultLocation,
  visits: defaultVisits,
  isNextPageEnable: false,
  pagination: defaultPagination,
  ordering: defaultOrdering,
  filters: defaultFilters,
  scrollTop: 0,
};

export const locationDetailSlice = createSlice({
  name: "locationDetail",
  initialState: initLocationDetailState,
  reducers: {
    cleanUpLocationDetailVisits: (state: ILocationDetailState) => {
      state.status = Status.success;
      state.errorMsg = null;
      state.visits = defaultVisits;
      state.pagination = defaultPagination;
      state.isNextPageEnable = false;
    },
    setLocationDetailLocationId: (state: ILocationDetailState, action: PayloadAction<number>) => {
      state.locationId = action.payload;
    },
    replaceVisits: (state: ILocationDetailState, action: PayloadAction<ILocationDetailVisit>) => {
      const newVisits = [...state.visits].map((point) => {
        if (point.collectionYardVisitId === action.payload.collectionYardVisitId) {
          return action.payload;
        }
        return point;
      });

      state.visits = newVisits;
    },
    setLocationDetailLocation: (state: ILocationDetailState, action: PayloadAction<ILocationDetailLocation>) => {
      state.location = action.payload;
    },
    setLocationDetailVisitsPagination: (state: ILocationDetailState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    setLocationDetailVisitsOrdering: (state: ILocationDetailState, action: PayloadAction<IOrdering>) => {
      state.ordering = action.payload;
    },
    setLocationDetailFilter: (state, action: PayloadAction<IFilterItem>) => {
      const findIdx = state.filters.items.findIndex((item) => item.id === action.payload.id);

      if (findIdx === -1) {
        state.filters.items.push(action.payload);
        return;
      }

      state.filters.items[findIdx] = action.payload;
    },
    removeLocationDetailFilter: (state, action: PayloadAction<string>) => {
      state.filters.items = state.filters.items.filter((item) => !item.id?.toString().startsWith(action.payload));
    },
    setLocationDetailVisitsScrollTop: (state: ILocationDetailState, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    resetLocationDetailState: () => {
      return initLocationDetailState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocationDetailVisitsThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;

        if (state.pagination.page > 1) {
          const mockedContainers = getSkeletonMockedArray(defaultVisit, 3, state.visits.length);
          state.visits = [...state.visits, ...mockedContainers];
        }
      })
      .addCase(getLocationDetailVisitsThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        state.location = action.payload.location;
        const visitsFromState = state.visits;
        const visitsFromResponse = action.payload.visits;
        const newLocationDetail = getSkeletonSuccessArray(visitsFromState, visitsFromResponse);

        state.visits = newLocationDetail;

        let isNextPageEnable = true;
        if (action.payload.visits.length < state.pagination.limit) {
          isNextPageEnable = false;
        }

        state.isNextPageEnable = isNextPageEnable;
        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getLocationDetailVisitsThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
        window.location.href = HOME_PAGE_PATH;
      });
  },
});

export const getLocationDetailVisitsThunk = createAsyncThunk<ILocationDetail, undefined, { state: RootState }>(
  "locationDetail/getLocationDetailVisitsThunk",
  async (_, { getState }) => {
    const state = getState();

    const requestBody = adaptRequestBodyFroBackend(state);
    const response = await axiosRequestApi.post(POST_LOCATION_DETAIL_VISITS_URL, requestBody);
    return adaptResponseBodyForFrontend(response.data);
  }
);

export const postQRCodeVerify = async (qr: string): Promise<string | undefined> => {
  try {
    const response = await axiosRequestApi.post(POST_QR_CODE_VERIFY_URL, { qr });
    return response.data.locationId;
  } catch (error) {
    console.error(error);
  }
};

export const {
  cleanUpLocationDetailVisits,
  setLocationDetailLocationId,
  setLocationDetailLocation,
  setLocationDetailVisitsPagination,
  setLocationDetailVisitsOrdering,
  setLocationDetailVisitsScrollTop,
  replaceVisits,
  resetLocationDetailState,
  setLocationDetailFilter,
  removeLocationDetailFilter,
} = locationDetailSlice.actions;

export const locationDetailReducer = locationDetailSlice.reducer;
