import { useEffect, useState } from "react";

import { BridgeAction, BridgeMessageType } from "../types/bridge";
import { handleBridgeMessage, triggerBridgeAction } from "../utils/bridge";

export const useQrScanner = () => {
  const [qrResult, setQrResult] = useState<string | null>(null);

  const startQrScanner = () => {
    triggerBridgeAction(BridgeAction.ScanQRCode);
  };

  useEffect(() => {
    const messageHandler = (event: MessageEvent) =>
      handleBridgeMessage(event, BridgeMessageType.QRScanResult, setQrResult);

    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return { qrResult, setQrResult, startQrScanner };
};
