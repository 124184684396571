import { useTranslation } from "react-i18next";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DotWrapper, DotWrapperVariant } from "../../../components/DotWrapper";
import { LoadingSkeleton } from "../../../components/LoadingSkeleton";
import { TableCellWithRightArrow } from "../../../components/TableCellWithRightArrow";
import { TableDateCell } from "../../../components/TableDateCell";
import { IColumnValuePair } from "../../../types/table";
import { isDateToday } from "../../../utils/dateHandler";
import { getTableColumnUtils } from "../../../utils/tables/getTableColumnUtils";
import { columnLabels, ColumnType } from "./locationsTableColumnTypes";
import { LocationsTableHeaderCell } from "./LocationsTableHeaderCell";

const columnMinWidthValuePairs: IColumnValuePair<ColumnType>[] = [{ name: ColumnType.wcpid, value: 105 }];

const columnFlexValuePairs: IColumnValuePair<ColumnType>[] = [
  {
    name: ColumnType.name,
    value: 3,
  },
  {
    name: ColumnType.address,
    value: 3,
  },
  {
    name: ColumnType.lastVisit,
    value: 3,
  },
  {
    name: ColumnType.wcpid,
    value: 2,
  },
];

export const useGetLocationsTableColumns = () => {
  const { t } = useTranslation();
  const { getFlex, getMinWidth } = getTableColumnUtils();

  const columns: GridColDef[] = columnLabels.map((label) => {
    const columnObject: GridColDef = {
      field: label,
      headerName: t(label),
      type: "string",
      renderHeader: (params: GridColumnHeaderParams) => {
        return <LocationsTableHeaderCell params={params} />;
      },
      flex: getFlex(label, columnFlexValuePairs),
      minWidth: getMinWidth(label, columnMinWidthValuePairs),
    };

    columnObject.renderCell = (params: GridRenderCellParams) => {
      if (params.row.isMocked) {
        return <LoadingSkeleton />;
      }

      switch (label) {
        case ColumnType.lastVisit: {
          const cellContent = <TableDateCell params={params} />;
          if (params.row.lastUnfinishedVisit && isDateToday(params.row.lastUnfinishedVisit)) {
            return <DotWrapper variant={DotWrapperVariant.Orange}>{cellContent}</DotWrapper>;
          }

          return cellContent;
        }
        case ColumnType.wcpid: {
          return <TableCellWithRightArrow mainComponent={<p>{params.value}</p>} />;
        }

        default:
          break;
      }
    };

    return columnObject;
  });

  return columns;
};
