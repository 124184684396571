import { isAnyOf } from "@reduxjs/toolkit";

import {
  getLocationsListThunk,
  removeLocationsFilter,
  setLocationsFilter,
  setLocationsListOrdering,
  setLocationsListPagination,
  setLocationsListSearch,
  setLocationsTabFilter,
} from "../../reducers/locationsReducer";
import { HOME_PAGE_PATH } from "../routes";
import { startAppListening } from "./listenerMiddleware";
import { resetLocations } from "./utils/resetLocations";

export const locationsListener = (): void => {
  startAppListening({
    matcher: isAnyOf(
      setLocationsListOrdering,
      setLocationsListSearch,
      setLocationsFilter,
      removeLocationsFilter,
      setLocationsTabFilter
    ),
    effect: async (_, { dispatch }) => {
      const pathname = location.pathname;
      switch (pathname) {
        case HOME_PAGE_PATH:
          resetLocations(dispatch);
          break;

        default:
          break;
      }
    },
  });
  startAppListening({
    actionCreator: setLocationsListPagination,
    effect: async (_, { dispatch }) => {
      dispatch(getLocationsListThunk());
    },
  });
};
