import { BackButton } from "../BackButton";

import styles from "./PageHeaderTitle.module.scss";

interface PageHeaderTitleProps {
  title: string;
  subtitle?: string;
  backTo?: string;
}

export const PageHeaderTitle: React.FC<PageHeaderTitleProps> = ({ title, subtitle, backTo }) => {
  const headerTitle = (
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    </div>
  );

  if (!backTo) return headerTitle;

  return <BackButton to={backTo}>{headerTitle}</BackButton>;
};
