import { IconNameEnum } from "../components/Icon";

export enum ILanguageValueEnum {
  cs = "cs",
  sk = "sk",
  hu = "hu",
  de = "de",
  en = "en",
}

export const languagesList = Object.values(ILanguageValueEnum);

export const languageValueMapToIconName: Record<ILanguageValueEnum, IconNameEnum> = {
  [ILanguageValueEnum.sk]: IconNameEnum.flagSlovakia,
  [ILanguageValueEnum.de]: IconNameEnum.flagGermany,
  [ILanguageValueEnum.cs]: IconNameEnum.flagCzech,
  [ILanguageValueEnum.hu]: IconNameEnum.flagHungary,
  [ILanguageValueEnum.en]: IconNameEnum.flagBritain,
};
