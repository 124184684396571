import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { POST_LOCATIONS_LIST_URL } from "../middleware/routes";
import { RootState } from "../store";
import { FilterLinkOperator, IFilterItem, IFilters } from "../types/filters";
import { ILocation, ILocations, ILocationsState, LocationsTabFilter } from "../types/locations";
import { IOrdering, IPagination } from "../types/pagination";
import { ISearch } from "../types/search";
import { Status } from "../types/state";
import { axiosRequestApi } from "../utils/axiosRequest";
import { getSkeletonMockedArray } from "../utils/tables/getSkeletonMockedArray";
import { getSkeletonSuccessArray } from "../utils/tables/getSkeletonSuccessArray";
import { adaptRequestBodyFroBackend } from "./utils/locationsAdapter";

export const defaultPagination = {
  page: 1,
  limit: 50,
};

export const defaultOrdering = {
  orderBy: "name",
  descending: false,
};

export const defaultSearch = {
  fulltext: "",
};

const defaultLocation: ILocation = {
  locationId: 0,
  name: "",
  address: "",
  wcpid: 0,
  lastVisit: null,
  lastUnfinishedVisit: null,
};

const defaultLocations: ILocations = getSkeletonMockedArray(defaultLocation, 50, 0);

const defaultFilters: IFilters = {
  items: [],
  linkOperator: FilterLinkOperator.And,
};

const initLocationsState: ILocationsState = {
  requestId: "",
  status: Status.idle,
  errorMsg: null,
  locations: defaultLocations,
  isNextPageEnable: false,
  pagination: defaultPagination,
  ordering: defaultOrdering,
  filters: defaultFilters,
  search: defaultSearch,
  scrollTop: 0,
  tabFilter: LocationsTabFilter.All,
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState: initLocationsState,
  reducers: {
    cleanUpLocationsList: (state: ILocationsState) => {
      state.status = Status.success;
      state.errorMsg = null;
      state.locations = defaultLocations;
      state.pagination = defaultPagination;
      state.isNextPageEnable = false;
    },
    replaceLocations: (state: ILocationsState, action: PayloadAction<ILocation>) => {
      const containersPoints = [...state.locations];
      const newContainers = containersPoints.map((point) => {
        if (point.locationId === action.payload.locationId) {
          return action.payload;
        }
        return point;
      });

      state.locations = newContainers;
    },
    setLocationsListPagination: (state: ILocationsState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
    setLocationsListOrdering: (state: ILocationsState, action: PayloadAction<IOrdering>) => {
      state.ordering = action.payload;
    },
    setLocationsFilter: (state, action: PayloadAction<IFilterItem>) => {
      const findIdx = state.filters.items.findIndex((item) => item.id === action.payload.id);

      if (findIdx === -1) {
        state.filters.items.push(action.payload);
        return;
      }

      state.filters.items[findIdx] = action.payload;
    },
    removeLocationsFilter: (state, action: PayloadAction<string>) => {
      state.filters.items = state.filters.items.filter((item) => !item.id?.toString().startsWith(action.payload));
    },
    setLocationsListSearch: (state: ILocationsState, action: PayloadAction<ISearch>) => {
      state.search = action.payload;
    },
    setLocationsTabFilter: (state: ILocationsState, action: PayloadAction<LocationsTabFilter>) => {
      state.tabFilter = action.payload;
    },
    setLocationsListScrollTop: (state: ILocationsState, action: PayloadAction<number>) => {
      state.scrollTop = action.payload;
    },
    resetLocationsState: () => {
      return initLocationsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocationsListThunk.pending, (state, action) => {
        state.status = Status.requesting;
        state.requestId = action.meta.requestId;

        if (state.pagination.page > 1) {
          const mockedContainers = getSkeletonMockedArray(defaultLocations, 3, state.locations.length);
          state.locations = [...state.locations, ...mockedContainers];
        }
      })
      .addCase(getLocationsListThunk.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) {
          return;
        }

        const containersFromState: ILocations = state.locations;
        const containersFromResponse: ILocations = action.payload;
        const newLocations = getSkeletonSuccessArray(containersFromState, containersFromResponse);

        state.locations = newLocations;

        let isNextPageEnable = true;
        if (action.payload.length < state.pagination.limit) {
          isNextPageEnable = false;
        }

        state.isNextPageEnable = isNextPageEnable;
        state.errorMsg = null;
        state.status = Status.success;
      })
      .addCase(getLocationsListThunk.rejected, (state) => {
        state.status = Status.error;
        state.errorMsg = "there has been an error";
      });
  },
});

export const getLocationsListThunk = createAsyncThunk<ILocations, undefined, { state: RootState }>(
  "locations/getLocationsListThunk",
  async (_, { getState }) => {
    const state = getState();

    const requestBody = adaptRequestBodyFroBackend(state);
    const response = await axiosRequestApi.post(POST_LOCATIONS_LIST_URL, requestBody);
    return response.data;
  }
);

export const {
  cleanUpLocationsList,
  setLocationsListPagination,
  setLocationsListOrdering,
  setLocationsListSearch,
  setLocationsTabFilter,
  setLocationsListScrollTop,
  replaceLocations,
  resetLocationsState,
  setLocationsFilter,
  removeLocationsFilter,
} = locationsSlice.actions;

export const locationsReducer = locationsSlice.reducer;
