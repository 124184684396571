import classNames from "classnames";

import styles from "./DotWrapper.module.scss";

export enum DotWrapperVariant {
  Green = "green",
  Orange = "orange",
}

export interface DotWrapperProps {
  variant?: DotWrapperVariant;
}

export const DotWrapper: React.FC<DotWrapperProps> = ({ children, variant = DotWrapperVariant.Green }) => {
  return (
    <div className={styles.container}>
      {children}
      <div className={classNames(styles.dot, styles[variant])}></div>
    </div>
  );
};
