import { resetAuthState } from "../../reducers/authReducer";
import { store } from "../../store";
import { ButtonWithoutDefaultStyling } from "../ButtonWithoutDefaultStyling";
import { Icon, IconNameEnum, IconTypeEnum } from "../Icon";

import styles from "./LogoutButton.module.scss";

export const LogoutButton: React.FC = () => {
  const handleLogout = () => {
    store.dispatch(resetAuthState());
  };

  return (
    <ButtonWithoutDefaultStyling onClick={handleLogout} className={styles.container}>
      <Icon name={IconNameEnum.logout} type={IconTypeEnum.grey} />
    </ButtonWithoutDefaultStyling>
  );
};
