import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { addVisitEditEvent } from "../../../reducers/visitEditReducer";

import styles from "./WasteTypeCard.module.scss";

interface WasteTypeCardProps {
  wasteId: number;
  isSelected?: boolean;
}

export const WasteTypeCard: React.FC<WasteTypeCardProps> = ({ wasteId, isSelected }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (isSelected) return;
    dispatch(addVisitEditEvent(wasteId));
  };

  return (
    <div className={classNames(styles.container, { [styles.selected]: isSelected })} onClick={handleClick}>
      <p className={styles.text}>{t(`enums.allWasteTypes.${wasteId}`)}</p>
    </div>
  );
};
