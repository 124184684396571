import { Divider } from "@mui/material";

import { LocationsTabsFilter } from "../../components/LocationsTabsFilter";
import { PageContainer } from "../../components/PageContainer";
import { StartReceivingWasteBanner } from "../../components/StartReceivingWasteBanner";
import { LocationsPageHeaderComponent } from "./LocationsPageHeaderComponent";
import { LocationsTable } from "./LocationsTable";

import styles from "./LocationsPage.module.scss";

export const LocationsPage: React.FC = () => {
  return (
    <PageContainer HeaderComponent={LocationsPageHeaderComponent}>
      <div className={styles.content}>
        <LocationsTabsFilter />
        <Divider />
        <div className={styles.spacer}></div>
        <LocationsTable />
        <StartReceivingWasteBanner />
      </div>
    </PageContainer>
  );
};
