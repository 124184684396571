import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GridEventListener, GridEvents, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";

import { LOCATION_DETAIL_PAGE_PATH } from "../../../middleware/routes";
import {
  getLocationDetailVisitsThunk,
  resetLocationDetailState,
  setLocationDetailLocation,
  setLocationDetailLocationId,
} from "../../../reducers/locationDetailReducer";
import { defaultOrdering } from "../../../reducers/locationsReducer";
import { setLocationsListOrdering } from "../../../reducers/locationsReducer";
import { getIsLocationsListStatusLoading, getLocationsOrdering } from "../../../selectors/locations";
import { ILocation } from "../../../types/locations";
import { SearchParams } from "../../../types/searchParams";
import { getRouteUrlWithParams } from "../../../utils/route";

interface LocationsTableHandlers {
  handleAdditionalRowClick: GridEventListener<GridEvents.rowClick>;
  handleSortModelChange: (model: GridSortModel) => void;
}

export const useGetLocationsTableHandlers = (): LocationsTableHandlers => {
  const dispatch = useDispatch();
  const ordering = useSelector(getLocationsOrdering);
  const isLoading = useSelector(getIsLocationsListStatusLoading);
  const history = useHistory();

  const handleAdditionalRowClick: GridEventListener<GridEvents.rowClick> = (params: GridRowParams<ILocation>) => {
    const locationId = params.row.locationId;

    dispatch(resetLocationDetailState());
    dispatch(setLocationDetailLocationId(Number(locationId)));
    dispatch(setLocationDetailLocation({ name: params.row.name, address: params.row.address }));
    dispatch(getLocationDetailVisitsThunk());
    history.push(getRouteUrlWithParams(LOCATION_DETAIL_PAGE_PATH, { [SearchParams.LocationId]: locationId }));
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (isLoading) {
      return;
    }

    const field = model[0].field;
    const newOrderBy = () => {
      switch (field) {
        default:
          return field;
      }
    };

    const newOrdering = {
      orderBy: newOrderBy(),
      descending: ordering.orderBy === newOrderBy() ? !ordering.descending : defaultOrdering.descending,
    };
    dispatch(setLocationsListOrdering(newOrdering));
  };

  const locationsTableHandlers: LocationsTableHandlers = {
    handleAdditionalRowClick,
    handleSortModelChange,
  };

  return locationsTableHandlers;
};
