import { useSelector } from "react-redux";

import { DataTable } from "../../../components/DataTable";
import { setLocationsListPagination, setLocationsListScrollTop } from "../../../reducers/locationsReducer";
import {
  getIsLocationsListStatusLoading,
  getLocations,
  getLocationsList,
  getLocationsScrollTop,
} from "../../../selectors/locations";
import { useGetLocationsTableColumns } from "./useGetLocationsTableColumns";
import { useGetLocationsTableHandlers } from "./useGetLocationsTableHandlers";

export const LocationsTable: React.FC = () => {
  const data = useSelector(getLocationsList);
  const columns = useGetLocationsTableColumns();
  const isLoading = useSelector(getIsLocationsListStatusLoading);
  const { handleAdditionalRowClick } = useGetLocationsTableHandlers();
  const scrollTop = useSelector(getLocationsScrollTop);
  const locationsState = useSelector(getLocations);

  return (
    <DataTable
      dataRows={data}
      columns={columns}
      scrollTop={scrollTop}
      setScrollTop={setLocationsListScrollTop}
      setPagination={setLocationsListPagination}
      isLoading={isLoading}
      sliceState={locationsState}
      handleAdditionalRowClick={handleAdditionalRowClick}
      hasDividerBetweenRows
    />
  );
};
