declare global {
  interface Window {
    ReactObserver?: ReactObserver;
  }
}

export interface ReactObserver {
  postMessage: (message: string) => void;
}

export enum BridgeAction {
  ScanQRCode = "scanQRCode",
}

export enum BridgeMessageType {
  QRScanResult = "QRScanResult",
}
