import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApplicationBreakpoint, ApplicationMode, IApplicationState } from "../types/application";
import { ISelectOption } from "../types/select";
import { Status } from "../types/state";
import { lastYears } from "../utils/yearsMocker";

const initApplicationState: IApplicationState = {
  applicationMode: ApplicationMode.light,
  applicationBreakpoint: ApplicationBreakpoint.desktop,
  sidePanelVisibility: false,
  loaderVisibility: true,
  uploadFileStatus: Status.idle,
  years: {
    lastYears,
    selectedYear: lastYears[0],
  },
};

export const applicationSlice = createSlice({
  name: "application",
  initialState: initApplicationState,
  reducers: {
    setApplicationMode: (state: IApplicationState, action: PayloadAction<ApplicationMode>) => {
      state.applicationMode = action.payload;
    },
    setApplicationBreakpoint: (state: IApplicationState, action: PayloadAction<ApplicationBreakpoint>) => {
      state.applicationBreakpoint = action.payload;
    },
    setSidePanelVisibility: (state: IApplicationState, action: PayloadAction<boolean>) => {
      state.sidePanelVisibility = action.payload;
    },
    selectYear: (state: IApplicationState, action: PayloadAction<ISelectOption>) => {
      state.years.selectedYear = action.payload;
    },
    setLoaderVisibility: (state: IApplicationState, action: PayloadAction<boolean>) => {
      state.loaderVisibility = action.payload;
    },
    resetApplicationState: () => {
      return initApplicationState;
    },
  },
});

export const {
  setApplicationMode,
  setApplicationBreakpoint,
  setSidePanelVisibility,
  selectYear,
  setLoaderVisibility,
  resetApplicationState,
} = applicationSlice.actions;

export const applicationReducer = applicationSlice.reducer;
