import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getEventsGroupedByWasteType } from "../../../selectors/visitEdit";
import { WasteWeightsByWasteType } from "../WasteWeightsByWasteType";

import styles from "./WasteWeights.module.scss";

export const WasteWeights: React.FC = () => {
  const { t } = useTranslation();
  const groupEventsByWasteType = useSelector(getEventsGroupedByWasteType);

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{t("wasteWeights.enterWasteSum")}</h4>
      {Object.entries(groupEventsByWasteType).map(([wasteTypeId, events]) => (
        <WasteWeightsByWasteType key={wasteTypeId} wasteTypeId={+wasteTypeId} events={events} />
      ))}
      {Object.values(groupEventsByWasteType).length === 0 && (
        <div className={styles.selectFirstItem}>{t("wasteWeights.selectFirstItem")}</div>
      )}
    </div>
  );
};
