import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { FilterOperator, IFilterItem } from "../../types/filters";
import { transformTimeToEndOfMinute } from "../../utils/dateHandler";
import { DateTimePicker } from "../DateTimePicker";

interface Props {
  fieldName: string;
  filterSelectorValue: (state: RootState, fieldName: string) => IFilterItem | undefined;
  filterUpdateAction: (item: IFilterItem) => void;
}

export const DateFilter: React.FC<Props> = ({ fieldName, filterSelectorValue, filterUpdateAction }) => {
  const minValueId = `${fieldName}Min`;
  const maxValueId = `${fieldName}Max`;
  const minValue = useSelector((state: RootState) => filterSelectorValue(state, minValueId));
  const maxValue = useSelector((state: RootState) => filterSelectorValue(state, maxValueId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <DateTimePicker
        value={minValue?.value ? new Date(minValue.value as string) : undefined}
        placeHolder={t("filters.dateFrom")}
        withIcon={true}
        onChange={(value) => {
          dispatch(
            filterUpdateAction({
              id: minValueId,
              name: fieldName,
              operator: FilterOperator.IsGreaterOrEqualTo,
              value: value.toISOString(),
            })
          );
        }}
      />
      <DateTimePicker
        value={maxValue?.value ? new Date(maxValue.value as string) : undefined}
        placeHolder={t("filters.dateTo")}
        withIcon={true}
        onChange={(value) => {
          dispatch(
            filterUpdateAction({
              id: maxValueId,
              name: fieldName,
              operator: FilterOperator.IsLessOrEqualTo,
              value: transformTimeToEndOfMinute(value.toISOString()),
            })
          );
        }}
      />
    </>
  );
};
